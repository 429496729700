import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Nimeni nu s-a născut soț sau soție. Orice familie, orice partener care vrea să fie împlinit ar trebui să fie deschis spre a învăța să fie și să facă ce este mai bine pentru relația lor.Acest curs este un ajutor pentru perioada de „părăsire a părinților” și de „alipire de partener” și continuă cu materiale care ajută la consolidarea relației. Cursul oferă multe elemente biblice pentru a dezvolta intimitatea la nivel spiritual cu Dumnezeu și intimitatea la nivel fizic.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Prin lucrul împreună la grup și temele ce trebuiesc făcute împreună de către soți, putem ajunge să ne cunoaștem mai bine nevoile reciproce, să comunicăm mai deschis să devenim cele mai potrivite persoane pentru împlinirea celorlalți membri ai familiei. Familia este un colț de rai numai când aducem modelul biblic de căsătorie în relația noastră.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Dacă tu vrei să stăpânești arta de a iubi și dorești să obții recompensele unei căsătorii extrem de fericite, va trebui să înveți principiile după care se poate zidi dragostea în căsnicia ta și să le practici în fiecare zi… trebuie să dorești să înveți și să fi gata să-ți investești însăși viața ta în aceasta. Eu îți pot spune din experiență: merită efortul!”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    